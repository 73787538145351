import React, { useState, useEffect } from 'react';
import PageTitle from '../layout/PageTitle';
import Footer from "../layout/Footer";
import { Container, Row, Col, Card, Nav, Tab } from "react-bootstrap";
import Vehicles from './Vehicles';
import Supervisors from './Supervisors';
import Drivers from './Drivers';
import Wards from './Wards';
import Zones from './Zones';
import Destinations from './Destinations';
import Barcodes from './Barcodes';
import { getFromAPI } from "../Utils/utils";

export default function Management() {
  const [activeTab, setActiveTab] = useState('vehicles');
    
    // Navtab data
    const tabData = [
        { key: 'vehicles', label: 'Vehicles', component: <Vehicles activeTab={activeTab}/> },
        { key: 'supervisors', label: 'Supervisors', component: <Supervisors activeTab={activeTab}  /> },
        { key: 'drivers', label: 'Drivers', component: <Drivers activeTab={activeTab} /> },
        { key: 'wards', label: 'Wards', component: <Wards activeTab={activeTab} /> },
        { key: 'zones', label: 'Zones', component: <Zones activeTab={activeTab} /> },
        { key: 'destinations', label: 'Destinations', component: <Destinations activeTab={activeTab}  /> },
        { key: 'barcodes', label: 'Barcodes', component: <Barcodes activeTab={activeTab}  /> }
    ];

    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Management" />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="vehicles" onSelect={setActiveTab}>
                                            <Row>
                                                <Col md={12}>
                                                    <Nav justify variant="pills" className="custom-navtab gap-2">
                                                        {tabData.map(({ key, label }) => (
                                                            <Nav.Item key={key}>
                                                                <Nav.Link eventKey={key}>{label}</Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                                <Col md={12}>
                                                    <Tab.Content>
                                                        {tabData.map(({ key, component }) => (
                                                            <Tab.Pane eventKey={key} key={key}>
                                                                {component}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

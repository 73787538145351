import React, { useContext } from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import MenuContext from '../../context/MenuContext'
import TopMenu from '../layout/TopMenu';
import Dashboard from '../dashboard/Dashboard';
import MappingAssignment from '../mapping_assignment/MappingAssignment';
import Login from '../Login';
import ForgotPassword from '../Forgotpassword';
import OTPEntry from '../OtpEntry';
import SetNewPassword from '../SetNewPassword';
import Management from '../management/Management';

export default function Index() {
  const context = useContext(MenuContext);
  const { iconMenu } = context;
  return (
    <>
    <div data-sidebar-size={iconMenu ? "lg" : "sm"}>
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Login />}></Route>
          <Route path="/dashboard" element={<><TopMenu /><Dashboard /></>} />
          <Route path="/mapping_assignment" element={<><TopMenu /><MappingAssignment /></>} />
          <Route path="/management" element={<><TopMenu /><Management /></>} />
          <Route path="/forgot" element={<><ForgotPassword /></>} />
          <Route path="/otp" element={<><OTPEntry /></>} />
          <Route path="/set-new-password" element={<><SetNewPassword /></>} />
        </Routes>
      </BrowserRouter>
    </div>
    </>
  )
}
import React, { useState } from 'react';
import Footer from "../layout/Footer";
import { Container, Row, Col, Card, Nav, Tab } from "react-bootstrap";
import PageTitle from '../layout/PageTitle';
import VehicleMapping from './VehicleMapping';
import VehicleAssignment from './VehicleAssignment';
import DestinationAssignment from './DestinationAssignment';
import DestinationMapping from './DestinationMapping';

export default function MappingAssignment() {
    const [activeTab, setActiveTab] = useState('VehicleMapping');

    // Navtab data
    const tabData = [
        { key: 'VehicleMapping', label: 'Vehicle Mapping', component: <VehicleMapping activeTab={activeTab} /> },
        // { key: 'supervisors', label: 'Supervisors', component: <Supervisors supervisorData={supervisorData} activeTab={activeTab} /> },
        { key: 'VehicleAssignment', label: 'Vehicle Assignment', component: <VehicleAssignment activeTab={activeTab} /> },
        { key: 'DestinationMapping', label: 'Destination Mapping', component: <DestinationMapping activeTab={activeTab} /> },
        { key: 'DestinationAssignment', label: 'Destination Assignment', component: <DestinationAssignment activeTab={activeTab} /> },
        
    ];
    return (
        <>
            <div className="page-wrapper">
                <div className="page-content">
                    <PageTitle breadcrumbLast="Mapping & Assignment" />
                    <Container>
                        <Row>
                            <Col md={12}>
                                <Card>
                                    <Card.Body>
                                        <Tab.Container id="left-tabs-example" defaultActiveKey="VehicleMapping" onSelect={setActiveTab}>
                                            <Row>
                                                <Col md={12}>
                                                    <Nav justify variant="pills" className="custom-navtab gap-2">
                                                        {tabData.map(({ key, label }) => (
                                                            <Nav.Item key={key}>
                                                                <Nav.Link eventKey={key}>{label}</Nav.Link>
                                                            </Nav.Item>
                                                        ))}
                                                    </Nav>
                                                </Col>
                                                <Col md={12}>
                                                    <Tab.Content>
                                                        {tabData.map(({ key, component }) => (
                                                            <Tab.Pane eventKey={key} key={key}>
                                                                {component}
                                                            </Tab.Pane>
                                                        ))}
                                                    </Tab.Content>
                                                </Col>
                                            </Row>
                                        </Tab.Container>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Footer />
            </div>
        </>
    )
}

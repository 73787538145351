import React, { useEffect, useState } from 'react';
import { Button, Card, Table, Modal, Row, Col, Form } from "react-bootstrap";
import JSZip from "jszip";
import $ from "jquery";
import DataTable from "datatables.net-dt";
import { postToAPI, getFromAPI, putToAPI, deleteFromAPI } from "../Utils/utils"; // Import your utility functions
import { toast } from 'react-toastify';

export default function Drivers({ activeTab }) {
    // Modal states
    const [showDriverModal, setShowDriverModal] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [validated, setValidated] = useState(false);
    const [driverData, setDriverData] = useState();
    const [userData, setUserData] = useState([]);

    // Form states
    const initialDriverData = {
        firstName: '',
        lastName: '',
        LicenceNo: '',
        email: '',
        employeeId: '',
        mobileNo: '',
        password: '',
        isActive: true
    };

    const [driverInputData, setDriverInputData] = useState(initialDriverData)
    const [driverIdToEdit, setDriverIdToEdit] = useState(null);
    const [driverIdToDelete, setDriverIdToDelete] = useState(null);

   // Fetch driver data
   const fetchDriverData = () => {
    getFromAPI(`driver/`)
        .then(data => {
            if (data && data.drivers) {
                setDriverData(data.drivers); // Set the driver data in state
            } else {
                console.error('Unexpected data structure:', data);
            }
        })
        .catch(error => {
            console.error('Error fetching driver data:', error);
        });
};

// Fetch user data
const fetchUserData = () => {
    getFromAPI(`user/`).then(data => {
        if (data) {
            setUserData(data);
        } else {
            console.error('Unexpected data structure:', data);
        }
    }).catch(error => {
        console.error('Error fetching user data:', error);
    });
};

// Initial data fetching
useEffect(() => {
    fetchDriverData();
    fetchUserData();
}, []);


const handleEmailChange = (e) => {
    let email_val = e.target.value.trim();
    setDriverInputData({ ...driverInputData, email: email_val });
    // const emailExists = driverData.some(driver => driver.user_data.email === email_val);
    // setisEmailExists(emailExists); 
}

const handleContactChange = (e) => {
    let con_val = e.target.value.trim();
    const isValidContact = /^[0-9+\-]*$/.test(con_val);
    if (isValidContact) {
        setDriverInputData({ ...driverInputData, mobileNo: con_val });
    }
}

    // Initialize DataTable
    DataTable.Buttons.jszip(JSZip);
    useEffect(() => {
        if (driverData && !$.fn.dataTable.isDataTable("#driverDataTable")) {
            $("#driverDataTable").DataTable({
                dom: 'Bflrtip',
                buttons: [
                    {
                        extend: 'excel',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M14 2H6C4.89 2 4 2.9 4 4V20C4 21.11 4.89 22 6 22H18C19.11 22 20 21.11 20 20V8L14 2M18 20H6V4H13V9H18V20M12.9 14.5L15.8 19H14L12 15.6L10 19H8.2L11.1 14.5L8.2 10H10L12 13.4L14 10H15.8L12.9 14.5Z" style="fill: currentcolor;"></path></svg> Export to Excel'
                    },
                    {
                        extend: 'pdf',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M6,2A2,2 0 0,0 4,4V20A2,2 0 0,0 6,22H18A2,2 0 0,0 20,20V8L14,2H6M6,4H13V9H18V20H6V4M8,12V14H16V12H8M8,16V18H13V16H8Z" style="fill: currentcolor;"></path></svg> Export to Pdf'
                    },
                    {
                        extend: 'print',
                        className: 'btn btn-export btn-md btn-lightdark',
                        text: '<svg viewBox="0 0 24 24" role="presentation" class="btn-icon"><path d="M19 8H5C3.9 8 3 8.9 3 10V16H7V20H17V16H21V10C21 8.9 20.1 8 19 8M17 18H7V14H17V18M19 12H5V10H19V12Z" style="fill: currentcolor;"></path></svg> Print'
                    }
                ],
                data: driverData.map((data, i) => {
                    return [
                        i + 1,
                        data.employeeId,
                        data.FirstName,
                        data.LastName,
                        data.LicenceNo,
                        data.user_data.email || 'N/A',
                        data.user_data.mobileNo || 'N/A',
                        data.isActive
                            ? `<span class="badge badge-soft-success">Active</span>`
                            : `<span class="badge badge-soft-danger">Inactive</span>`,
                        data.id
                    ];
                }),
                columns: [
                    { title: "No." },
                    { title: "Driver ID" },
                    { title: "First Name" },
                    { title: "Last Name" },
                    { title: "Licenec No" },
                    { title: "Email" },
                    { title: "Contact No" },
                    { title: "Status" },
                    
                    {
                        title: "Action",
                        render: function (data, type, row) {
                            return `
                                <div class="d-flex">
                                    <button class="btn btn-soft-primary table-btn me-2">
                                        <i class="bi bi-pencil-square edit-driver" data-driver-id="${row[8]}"></i>
                                    </button>
                                    <button class="btn btn-soft-danger table-btn">
                                        <i class="bi bi-trash3 delete-driver" data-driver-id="${row[8]}"></i>
                                    </button>
                                </div>
                            `;
                        }
                    },
                ],
                autoWidth: false,
                columnDefs: [
                    {
                        targets: -1,
                        orderable: false,
                    },
                ],
                language: {
                    search: "",
                    searchPlaceholder: "Search...",
                    paginate: {
                        previous: "«",
                        next: "»",
                    },
                },
            });

            // Handle edit button click
            $("#driverDataTable").on('click', '.edit-driver', function () {
                const driverId = $(this).data('driver-id');
                handleShowDriverModal("Edit Driver", driverId, true);
            });

            // Handle delete button click
            $("#driverDataTable").on('click', '.delete-driver', function () {
                const driverId = $(this).data('driver-id');
                setDriverIdToDelete(driverId);
                setShowDeleteModal(true);
            });
        }
    }, [driverData]);

    const UpdateDriverDataTable = () => {
        const PODT = $('#driverDataTable').DataTable();
        getFromAPI("driver/")
            .then(response => {
                setDriverData(response.drivers);
                let DTData = response.drivers.map((data, i) => [
                    i + 1,
                    data.employeeId,
                    data.FirstName,
                    data.LastName,
                    data.LicenceNo,
                    data.user_data.email || 'N/A',
                    data.user_data.mobileNo || 'N/A',
                    data.isActive
                        ? `<span class="badge badge-soft-success">Active</span>`
                        : `<span class="badge badge-soft-danger">Inactive</span>`,
                    data.id 
                ]);
            PODT.clear().rows.add(DTData).draw();
            })
            .catch(error => {
            console.error('Error:', error);
            });
    }

    useEffect(() => {
        fetchDriverData();
    }, []);

   
    // Show modal for adding or editing a driver
    const handleShowDriverModal = (title, driverId = null, isEdit = false) => {
        setModalTitle(title);
        setShowDriverModal(true);

        if (isEdit && driverId) {
            setDriverIdToEdit(driverId);

            // Fetch driver data for editing
            getFromAPI(`driver/?id=${driverId}`).then(driver_data => {
                const Driver_Data = driver_data.drivers[0];
                if (Driver_Data) {
                    // Fetch user data associated with the driver
                    setDriverInputData({
                        firstName: Driver_Data.FirstName || '',
                        lastName: Driver_Data.LastName || '',
                        LicenceNo: Driver_Data.LicenceNo || '',
                        email: Driver_Data.user_data.email || '',
                        employeeId: Driver_Data.employeeId || '',
                        mobileNo: Driver_Data.user_data.mobileNo || '',
                        password: Driver_Data.user_data.password || '',
                        isActive: Driver_Data.isActive || false
                    });
                } else {
                    setDriverIdToEdit(null);
                    setDriverInputData({
                        firstName: '',
                        lastName: '',
                        LicenceNo: '',
                        email: '',
                        employeeId: '',
                        mobileNo: '',
                        password: '',
                        isActive: false,
                    });
                }
            });
        }
    }

    // const handleShowDriverModal = (title, driverId = null, isEdit = false) => {
    //     setModalTitle(title);
    //     setShowDriverModal(true);
    
    //     if (isEdit && driverId) {
    //         setDriverIdToEdit(driverId);
    
    //         getFromAPI(`driver/`).then(data => {
    //             if (Array.isArray(data)) {
    //                 // Find the vehicle with the specified ID
    //                 const driverData = data.find(driver => driver.id === driverId);
    
    //                 if (driverData) {
    //                     setDriverInputData({
    //                         firstName: driverData.FirstName || '',
    //                         lastName: driverData.LastName || '',
    //                         LicenceNo: driverData.LicenceNo || '',
    //                         email: driverData.user_data.email || '',
    //                         employeeId: driverData.employeeId || '',
    //                         mobileNo: driverData.user_data.mobileNo || '',
    //                         password: driverData.user_data.password || '',
    //                         isActive: driverData.isActive || false
    //                     });
    //                 } else {
    //                     console.error('driver not found in data:', driverId);
    //                 }
    //             } else {
    //                 console.error('Unexpected data structure:', data);
    //             }
    //         }).catch(error => {
    //             console.error('Error fetching driver:', error);
    //         });
    //     } else {
    //         setDriverIdToEdit(null);
    //                 setDriverInputData({
    //                     firstName: '',
    //                     lastName: '',
    //                     LicenceNo: '',
    //                     email: '',
    //                     employeeId: '',
    //                     mobileNo: '',
    //                     password: '',
    //                     isActive: false,
    //                 });
    //     }
    // };


    const handleCloseModal = () => {
        setShowDriverModal(false);
        UpdateDriverDataTable();
        setValidated(false);
        setDriverIdToEdit(null);
        setDriverInputData({
            firstName: '',
            lastName: '',
            LicenceNo: '',
            email: '',
            employeeId: '',
            mobileNo: '',
            password: '',
            isActive: true,
        })
    };

    // Save driver data
    const handleSaveDriver = async (e) => {

        e.preventDefault();
        const form = e.currentTarget;
        const mobregex = /^\+[0-9]{12}$/;

        if (form.checkValidity()  === false) {
            e.stopPropagation();
            setValidated(true);
            toast.error('Please fill all required fields','error');
        } else {
            setValidated(true);
            if(!mobregex.test(driverInputData.mobileNo.trim())) {
                toast.error('Please enter valid mobile number with +91 prefix','error');
                return
            }
            try {
                let response;
                if (driverIdToEdit) {
                    response = await putToAPI(`driver/${driverIdToEdit}/`, driverInputData);
                    if (response.status) {
                        fetchDriverData();
                        handleCloseModal();
                        UpdateDriverDataTable();
                    }
                } else {
                    const isDuplicateLicenceNo = driverData.some(driver => {
                        return (
                            driver.LicenceNo === driverInputData.LicenceNo &&
                            (!driverIdToEdit || driver.id !== driverIdToEdit)
                        );
                    });
        
                    const isDuplicateEmployeeId = driverData.some(driver => {
                        return (
                            parseInt(driver.employeeId) === parseInt(driverInputData.employeeId) &&
                            (!driverIdToEdit || driver.id !== driverIdToEdit)
                        );
                    });
        
                    const isDuplicateEmail = driverData.some(driver => {
                        return (
                            driver.user_data.email === driverInputData.email &&
                            (!driverIdToEdit || driver.id !== driverIdToEdit)
                        );
                    });
                    
                    const isDuplicateMobileNo = driverData.some(driver => {
                        return (
                            driver.user_data.mobileNo === driverInputData.mobileNo &&
                            (!driverIdToEdit || driver.id !== driverIdToEdit)   
                        );
                    });
                    
        
                    if (isDuplicateLicenceNo) {
                        toast.error('Licence number already exists.');
                        return;
                    }
        
                    if (isDuplicateEmployeeId) {
                        toast.error('Employee ID already exists.');
                        return;
                    }
        
                    if (isDuplicateEmail) {
                        toast.error('Email already exists.');
                        return;
                    }
        
                    if (isDuplicateMobileNo) {
                        toast.error('Mobile number already exists.');
                        return;
                    }
                    response = await postToAPI("add_driver_or_supervisor/", driverInputData);
                    if (response.status) {
                        fetchDriverData();
                        handleCloseModal();
                        UpdateDriverDataTable();
                    }
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }
    };

    // Confirm delete
    const handleConfirmDelete = async () => {
        try {
            let vehicleMapData = await getFromAPI(`vehicleMapping/?driverId=${driverIdToDelete}`);
            let vehicleAssignData = await getFromAPI(`VehicleAssignment/?driverId=${driverIdToDelete}`);
            // Delete Driver only if it is not mapped to any vehicle
            if(vehicleMapData.length === 0 && vehicleAssignData.length === 0) {
                await deleteFromAPI(`driver/${driverIdToDelete}/`);
                setShowDeleteModal(false);
                fetchDriverData();
                UpdateDriverDataTable();
            } else {
                toast.error("Selected Driver is assigned in vehicle mapping. Make sure that Driver is not assigned before delete.", 'error');
            }
        } catch (error) {
            console.error('Error deleting driver:', error);
        }
    };

    const handleCheckboxChange = (event) => {
        setDriverInputData({ ...driverInputData, isActive: event.target.checked });
    };

    useEffect(() => {
        if (activeTab == "drivers")
        {
            fetchDriverData();
        }
    }, [activeTab]);

    return (
        <>
            <div className="d-flex align-items-center flex-wrap gap-2 justify-content-between my-3">
                <Card.Title className="mb-0">Drivers</Card.Title>
                <Button variant="primary" onClick={() => handleShowDriverModal("Add Driver")}>
                    <i className="bi bi-plus-square me-2"></i> Add Driver
                </Button>
            </div>

            <Table responsive bordered className="mb-0 table-nowrap" id="driverDataTable">
                <thead className="table-light">
                </thead>
            </Table>

            {/* Driver Modal */}
            <Modal centered size="lg" show={showDriverModal} onHide={handleCloseModal}>
                <Modal.Header closeButton>
                    <Modal.Title>{modalTitle}</Modal.Title>
                </Modal.Header>
                <Form noValidate validated={validated} onSubmit={(e) => handleSaveDriver(e)}>
                <Modal.Body>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>First Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={driverInputData.firstName}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, firstName: e.target.value })} required isInvalid={validated && !driverInputData.firstName.trim()}
                                />
                                <Form.Control.Feedback type="invalid">First Name is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Last Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={driverInputData.lastName}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, lastName: e.target.value })} required isInvalid={validated && !driverInputData.lastName.trim()}
                                />
                                <Form.Control.Feedback type="invalid">Last Name is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>

                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Label>Email</Form.Label>
                                <Form.Control
                                    type="email"
                                    value={driverInputData.email}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, email: e.target.value })} required isInvalid={validated && !driverInputData.email.trim()}
                                />
                                <Form.Control.Feedback type="invalid">Email is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                    <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Driver ID</Form.Label>
                                <Form.Control
                                    type="number"
                                    value={driverInputData.employeeId}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, employeeId: e.target.value })} required isInvalid={validated && !driverInputData.employeeId}
                                />
                                <Form.Control.Feedback type="invalid">Driver ID is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Contact No</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={driverInputData.mobileNo}
                                    onChange={(e) => handleContactChange(e)} required isInvalid={validated && !driverInputData.mobileNo.trim()}
                                />
                                <Form.Control.Feedback type="invalid">Contact No is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Licenec No</Form.Label>
                                <Form.Control
                                    type="text"
                                    value={driverInputData.LicenceNo}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, LicenceNo: e.target.value })} required isInvalid={validated && !driverInputData.LicenceNo.trim()}
                                />
                                <Form.Control.Feedback type="invalid">Licenec No is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col lg={6}>
                            <Form.Group className="mb-3">
                                <Form.Label>Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    value={driverInputData.password}
                                    onChange={(e) => setDriverInputData({ ...driverInputData, password: e.target.value })} required isInvalid={validated && !driverInputData.password.trim()}
                                />
                                <Form.Control.Feedback type="invalid">Password is required.</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={12}>
                            <Form.Group className="mb-3">
                                <Form.Check
                                    type="checkbox"
                                    label="Active"
                                    checked={driverInputData.isActive}
                                    onChange={handleCheckboxChange}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" type='submit' >
                        Save
                    </Button>
                    <Button variant="secondary" onClick={handleCloseModal}>
                        Close
                    </Button>
                </Modal.Footer>
                </Form>
            </Modal>

            {/* Delete Confirmation Modal */}
            <Modal centered show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
                <Modal.Body className="text-center">
                    <div className="avatar avatar-xxxl bg-border-soft-danger rounded-circle text-danger mx-auto ">
                        <i className="bi bi-trash"></i>
                    </div>
                    <h4 className="fw-semibold mt-4">Are you sure?</h4>
                    <p className="text-muted fs-18">Do you really want to delete this record?</p>
                    <div className="d-flex gap-3">
                        <Button variant="light w-50" onClick={() => setShowDeleteModal(false)}>
                            Cancel
                        </Button>
                        <Button variant="danger w-50" onClick={handleConfirmDelete}>
                            Delete
                        </Button>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    );
}

import { useEffect } from 'react';
import './App.css';
import Routes from "./components/routes/Index";
import MenuState from './context/MenuState';
import { ToastContainer } from "react-toastify";

function App() {
  useEffect(() => {
    // Function to disable autocomplete on all input elements
    const disableAutoComplete = () => {
      const inputs = document.querySelectorAll('input');
      inputs.forEach(input => {
        input.setAttribute('autocomplete', 'off');
      });
    };

    // Initial call to disable autocomplete for inputs already in the DOM
    disableAutoComplete();

    // Use MutationObserver to listen for any added input elements
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.addedNodes.length > 0) {
          disableAutoComplete();
        }
      });
    });

    // Observe changes to the document body
    observer.observe(document.body, {
      childList: true, // Watch for direct children being added or removed
      subtree: true,   // Watch the entire subtree
    });

    // Cleanup observer on component unmount
    return () => observer.disconnect();
  }, []);
  return (
    <>
      <MenuState>
        <Routes />
      </MenuState>
      <ToastContainer position="top-left" autoClose={2000} hideProgressBar theme="colored" />
    </>
  );
}

export default App;
